<template>
    <div class="article-panel taskdata-panel">
        <div class="taskdata-panel-inner">
            <h3 class="g-title">项目资料</h3>
            <div class="article-bd">
                <div class="data-list-wraper">
                    <article class="data-item">
                        <div class="data-item-inner">
                            <p class="tit">①&nbsp;项目招标日程表</p>
                            <p class="desc">项目发布成功会自动生成项目招标日程表记录您的项目进度，您可以随时查看并进行打印。</p>
                            <el-button
                                type="primary"
                                @click="showFile(1)"
                                v-if="file.log"
                            >立即查看</el-button>
                            <el-button
                                type="info"
                                v-else
                            >暂未生成</el-button>
                        </div>
                    </article>
                    <article class="data-item">
                        <div class="data-item-inner">
                            <p class="tit">②&nbsp;招标公告</p>
                            <p class="desc">
                                项目发布成功会自动生成招标公告电子版，您可
                                以点击查看并进行打印。
                            </p>
                            <el-button
                                type="primary"
                                @click="showFile(2)"
                                v-if="file.task"
                            >立即查看</el-button>
                            <el-button
                                type="info"
                                v-else
                            >暂未生成</el-button>
                        </div>
                    </article>
                    <article class="data-item">
                        <div class="data-item-inner">
                            <p class="tit">③&nbsp;项目邀请函</p>
                            <p class="desc">
                                一旦发出邀请函，则自动生成项目邀请函，邀请
                                函仅保存最后发出的电子版。
                            </p>
                            <el-button
                                type="primary"
                                @click="showFile(3)"
                                v-if="file.enroll"
                            >立即查看</el-button>
                            <el-button
                                type="info"
                                v-else
                            >暂未生成</el-button>
                        </div>
                    </article>
                    <article class="data-item">
                        <div class="data-item-inner">
                            <p class="tit">④&nbsp;项目确认函</p>
                            <p class="desc">
                                所邀请极客一旦确认合作，则自动生成项目确认
                                涵，您可以点击查看并进行打印。
                            </p>
                            <el-button
                                type="primary"
                                @click="showFile(4)"
                                v-if="file.enrolled"
                            >立即查看</el-button>
                            <el-button
                                type="info"
                                v-else
                            >暂未生成</el-button>
                        </div>
                    </article>
                    <article class="data-item">
                        <div class="data-item-inner">
                            <p class="tit">⑤&nbsp;项目酬金托管证明</p>
                            <p class="desc">雇主托管酬金成功，则会自动生成项目酬金托管证明，您可以点击查看并进行打印。</p>
                            <el-button
                                type="primary"
                                @click="showFile(5)"
                                v-if="file.remuneration"
                            >立即查看</el-button>
                            <el-button
                                type="info"
                                v-else
                            >暂未生成</el-button>
                        </div>
                    </article>
                    <article class="data-item">
                        <div class="data-item-inner">
                            <p class="tit">⑥&nbsp;项目履约保证金托管证明</p>
                            <p class="desc">工程极客成功托管履约保证金会自动生成履约保证金托管证明，您可以点击查看并进行打印。</p>
                            <el-button
                                type="primary"
                                @click="showFile(6)"
                                v-if="file.bond"
                            >立即查看</el-button>
                            <el-button
                                type="info"
                                v-else
                            >暂未生成</el-button>
                        </div>
                    </article>
                    <article class="data-item">
                        <div class="data-item-inner">
                            <p class="tit">⑦&nbsp;项目完结确认书</p>
                            <p class="desc">雇主确认支付全部项目酬金后，则会自动生成项目完结确认书，您可以点击查看并进行打印。</p>
                            <el-button
                                type="primary"
                                @click="showFile(7)"
                                v-if="file.complate"
                            >立即查看</el-button>
                            <el-button
                                type="info"
                                v-else
                            >暂未生成</el-button>
                        </div>
                    </article>
                    <article class="data-item">
                        <div class="data-item-inner">
                            <p class="tit">⑧&nbsp;项目终止确认书</p>
                            <p class="desc">如果项目由于各种原因结束，则会自动生成项目终止确认书，您可以点击查看并进行打印。</p>
                            <el-button
                                type="primary"
                                @click="showFile(8)"
                                v-if="file.end"
                            >立即查看</el-button>
                            <el-button
                                type="info"
                                v-else
                            >暂未生成</el-button>
                        </div>
                    </article>
                    <article class="data-item">
                        <div class="data-item-inner">
                            <p class="tit">⑨&nbsp;电子合同</p>
                            <p class="desc">如项目通过询龙网线上签订电子合同会自动生成电子合同模板，您可以点击查看并打印。</p>
                            <el-button
                                type="primary"
                                @click="showFile(9)"
                                v-if="file.contract"
                            >立即查看</el-button>
                            <el-button
                                type="info"
                                v-else
                            >暂未生成</el-button>
                        </div>
                    </article>
                </div>
                <div>
                    <p>
                        注:
                        <span style="font-size: 14px;">电子合同需双方均完成签署才具备法律效应！</span>
                    </p>
                </div>
                <div style="width:100%;text-align:center;height:80px;line-height:80px;">
                    <el-button @click="backPage">返回</el-button>
                </div>
            </div>
        </div>
        <!-- 右侧弹出层详情可查看各个文档详情 详情内容为后台生成的图片，其中极客合同可以直接查看，雇主的合同需要点击后生成，和极客的有不同  -->
        <el-drawer
            class="taskdata-draw"
            :visible.sync="drawer"
            size="900px"
        >
            <div class="pdf_box">
                <iframe
                    class="contract_iframe"
                    :src="pdfUrl"
                ></iframe>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
    name: "taskdata",
    metaInfo() {
        return {
            title: "项目资料 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    components: {},
    data() {
        return {
            employeeScore: 3.7, // 雇主信用评分
            drawer: false, // 是否可见右侧弹出抽屉
            task: "",
            file: {
                task: "", //招标公告
                enroll: "", //项目邀请函
                enrolled: "", //项目确认函
                sure: "", //项目初步意向确认书
                remuneration: "", //项目酬金托管证明
                bond: "", //项目履约保证金托管证明
                complate: "", //项目完结确认函
                end: "", //项目终止确认函
                contract: "" //电子合同
            },
            trade: "",
            is_subscribe: 0,
            uk: "",
            pdfUrl: "",
            pdfViewerUrl: this.websiteConfigs.sourceUrl + "/upload/pdf/web/viewer.html?file="
        };
    },
    computed: {
        ...mapState(["userInfo"])
    },
    methods: {
        //获取项目信息
        loadData: function () {
            let _this = this;
            _this.post(
                "/task/task/detail_todo",
                { tk: _this.$route.query.tk },
                res => {
                    if (res.code == 200) {
                        _this.task = res.data;
                        _this.trade = res.data.trade;
                        _this.uk = res.data.trade.geek.uk;
                    } else {
                        if (res.msg) {
                            _this.$message.success(res.msg);
                        } else {
                            _this.$message.success("获取失败");
                        }
                    }
                }
            );
        },

        //获取项目文件
        loadFileData: function () {
            let _this = this;
            _this.post(
                "/task/task/task_file",
                { tk: _this.$route.query.tk },
                res => {
                    if (res.code == 200) {
                        _this.file = res.data;
                    } else {
                        if (res.msg) {
                            _this.$message.success(res.msg);
                        } else {
                            _this.$message.success("获取失败");
                        }
                    }
                }
            );
        },

        //发送消息
        sendMessage(val) {
            //侧边栏是否保持展开
            if (val && val.isShowDraw == "isShowDraw") {
                sessionStorage.setItem("isShowDraw", true);
            }
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            ); //存储当前值
            this.$router.push("/user/message/sendmsg?uk=" + val);
        },

        //是否关注作者
        subscribe() {
            let _this = this;
            this.post(
                "/user/index/is_subscribe",
                {
                    uk: _this.uk
                },
                function (res) {
                    if (res.code == 200) {
                        if (res.data.is_subscribe == 1) {
                            _this.is_subscribe = 1;
                        } else {
                            _this.is_subscribe = 0;
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },

        //关注用户
        keep(uk) {
            let _this = this;
            this.post(
                "/user/user/collect_user",
                {
                    uk: uk
                },
                function (res) {
                    if (res.code == 200) {
                        if (res.data == 1) {
                            //关注成功
                            _this.is_subscribe = 1;
                            _this.$message({
                                message: res.msg,
                                type: "success"
                            });
                        } else {
                            //取消关注
                            _this.is_subscribe = 0;
                            _this.$message({
                                message: res.msg,
                                type: "success"
                            });
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },

        //查看电子合同
        showFile(num) {
            switch (num) {
                case 1: //项目日志
                    this.pdfUrl = this.pdfViewerUrl + this.file.log;
                    break;
                case 2: //招标公告
                    this.pdfUrl = this.pdfViewerUrl + this.file.task;
                    break;
                case 3: //项目邀请函
                    this.pdfUrl = this.pdfViewerUrl + this.file.enroll;
                    break;
                case 4: //项目确认函
                    this.pdfUrl = this.pdfViewerUrl + this.file.enrolled;
                    break;
                case 5: //项目酬金托管证明
                    this.pdfUrl = this.pdfViewerUrl + this.file.remuneration;
                    break;
                case 6: //项目履约保证金托管证明
                    this.pdfUrl = this.pdfViewerUrl + this.file.bond;
                    break;
                case 7: //项目完结确认函
                    this.pdfUrl = this.pdfViewerUrl + this.file.complate;
                    break;
                case 8: //项目终止确认函
                    this.pdfUrl = this.pdfViewerUrl + this.file.end;
                    break;
                case 9: //电子合同
                    this.pdfUrl = this.pdfViewerUrl + this.file.contract;
                    break;
                default:
                    break;
            }

            this.drawer = true;
        },

        //返回上一页
        backPage() {
            let back_page = sessionStorage.getItem("back_page");
            if (back_page) {
                this.$router.push(back_page);
            } else {
                this.$router.go(-1); //返回上一层
            }
        }
    },
    created() {
        this.loadData();
        this.loadFileData();
        setTimeout(() => {
            this.subscribe();
        }, 1000);
    }
};
</script>

<style lang="less" scroped>
@import "../../../styles/taskData.less";
.pdf_box {
    width: 100%;
    padding: 0 20px 0 20px;
    .contract_iframe {
        border-radius: 8px;
        border: none;
        border-bottom: 1px solid #ddd;
        overflow: hidden;
        width: 100%;
        height: 740px;
    }
}
</style>